<template>
  <div class="login-container">
    <vue-particles
      class="login-container"
      color="#39AFFD"
      :particleOpacity="0.7"
      :particlesNumber="100"
      shapeType="circle"
      :particleSize="4"
      linesColor="#8DD1FE"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <span class="logo">
      <span class="logo_first">
        <el-image style="width: 50px; height: 50px" :src="logoUrl" fit="cover"></el-image>
      </span>
      <span>商</span>
      <span>盈</span>
      <span>管</span>
      <span>理</span>
      <span>系</span>
      <span>统</span>
    </span>
    <span class="welText"> 欢迎使用商盈管理系统，本系统由睿达物联网唯一支持 </span>
    <div class="loginContent">
      <div class="login_form">
        <el-input placeholder="请输入账号" v-model="account" clearable>
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
        <el-input
          placeholder="请输入密码"
          v-model="password"
          show-password
          style="margin-top: 15px"
          clearable
        >
          <i slot="prefix" class="el-input__icon el-icon-lock"></i>
        </el-input>
        <div class="code">
          <el-input
            placeholder="验证码"
            v-model="validatecode"
            class="code_input"
            clearable
          >
            <i slot="prefix" class="el-input__icon el-icon-connection"></i>
          </el-input>
          <validate-code ref="ref_validateCode" class="codeimg" @change="changeCode" />
        </div>
        <el-button type="primary" class="login_btn" @click="handleLogin"
          >立即登录</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import userApi from "../api/user";
import validateCode from "../views/ValidateCode";

export default {
  components: {
    validateCode,
  },
  data() {
    return {
      account: "",
      password: "",
      code: "",
      validatecode: "",
      logoUrl: require("../assets/xr.png"),
    };
  },
  created() {
    localStorage.setItem("shangytoken", "");
  },
  mounted() {
    //绑定键盘监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    keyDown(e) {
      if (e.keyCode == 13) {
        this.handleLogin();
      }
    },
    changeCode(value) {
      this.checkCode = value;
    },
    handleLogin() {
      if (this.validatecode.toUpperCase() === this.checkCode) {
        console.log("验证成功");
      } else {
        this.$message({
          showClose: true,
          message: "验证码错误",
          type: "error",
        });
        this.validatecode = "";
        this.$refs["ref_validateCode"].draw();
        return;
      }
      let param = {
        username: this.account,
        password: this.password,
        code: this.validatecode,
      };
      userApi.login(param).then((res) => {
        if (res.data.code !== 200) {
          this.$notify({
            title: "错误",
            message: res.data.message,
            position: "bottom-right",
            type: "error",
          });
          return;
        }
        const tokenStr = res.headers["authorization"];
        this.$store.commit("SET_TOKEN", tokenStr);
        this.$router.replace("/index");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  height: 100%;
  opacity: 1;
  background: rgba(240, 242, 245, 1);
  position: relative;
  .login-bg {
    width: 100%;
    height: 100%;
  }
  .loginContent {
    position: relative;
    top: -100%;
    width: 45%;
    height: 100%;
    opacity: 0.7;
    background: rgba(255, 255, 255, 1);
    float: right;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    .login_form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 40%;
      height: 40%;
      .code {
        width: 100%;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .code_input {
          width: 70%;
        }
        .codeimg {
          width: 25%;
          border-radius: 2px;
        }
      }
      .login_btn {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
  .logo,
  .welText {
    position: absolute;
  }
  .logo {
    top: 393px;
    left: 330px;
    color: rgba(64, 158, 255, 1);
    font-size: 28px;
    font-weight: bold;
    span {
      margin-left: 10px;
      &:nth-child(1) {
        font-size: 48px;
        margin-right: 15px;
      }
    }
  }
  .welText {
    top: 460px;
    left: 305px;
    color: rgba(0, 0, 0, 1);
  }
}
</style>
